namespace Umbrella.CustomerService.CustomerCard {
    import AgentFacetService = Umbrella.Modules.AgentFacetService;

    interface ExtendedPersonModel extends PersonModel {
        birthDate: Date;
    }

    export abstract class EditCustomerDataComponent {
        public person: PersonModel;
        public writableFields: WritablePersonDataFieldsModel;
        public personSynchronizedObjects: SynchronizedObjectModel[];
        public personClone: ExtendedPersonModel;
        public editMode: boolean;
        protected updating: boolean;

        protected constructor(
            protected agentFacetService: AgentFacetService,
            protected toastMessageService: ToastMessageService,
            protected personInfoService: CustomerCardPersonInfoService
        ) { }

        public $onInit() {
            this.clonePerson();
        }

        public hasUpdateableContactFields(): boolean {
            return (
                this.isAuthorizedToUpdateField(PersonFieldTypes.PhoneNumber) ||
                this.isAuthorizedToUpdateField(PersonFieldTypes.EmailAddress)
            );
        }

        public allowsMultipleTypesOfTheSameTelephoneNumber(): boolean {
            return (
                this.writableFields &&
                this.writableFields.capabilities &&
                this.writableFields.capabilities.multipleEqualPhoneTypesAllowed
            );
        }

        public allowsUnknownPhoneNumberTypes(): boolean {
            return (
                this.writableFields &&
                this.writableFields.capabilities &&
                this.writableFields.capabilities.unknownPhoneTypeAllowed
            );
        }

        public isAuthorizedToUpdateField(fieldType: PersonFieldTypes): boolean {
            const fieldName = PersonFieldTypes[fieldType.toString()];
            const isFieldFoundInWritableFieldCollection =
                this.writableFields &&
                this.writableFields.fields &&
                !!this.writableFields.fields.find(x => x === fieldName);
            return isFieldFoundInWritableFieldCollection && this.hasPermissionToUpdateField(fieldType);
        }

        public isFieldUpdateable(field: string): boolean {
            return this.editMode && this.isAuthorizedToUpdateField(PersonFieldTypes[field]);
        }

        public isPhoneNumberUpdateable(): boolean {
            return this.editMode && this.isAuthorizedToUpdateField(PersonFieldTypes.PhoneNumber);
        }

        public isPhoneTypeUpdateable(): boolean {
            return this.editMode && this.isAuthorizedToUpdateField(PersonFieldTypes.PhoneType);
        }

        public isPhoneTypeViewable(): boolean {
            return this.isAuthorizedToUpdateField(PersonFieldTypes.PhoneType);
        }

        public isEmailAddressUpdateable(): boolean {
            return this.editMode && this.isAuthorizedToUpdateField(PersonFieldTypes.EmailAddress);
        }

        private hasPermissionToUpdateField(fieldName: PersonFieldTypes): boolean {
            switch (fieldName) {
                case Umbrella.PersonFieldTypes.BirthDate:
                    return window.user.permissions.updateBirthDate;
                case Umbrella.PersonFieldTypes.EmailAddress:
                    return window.user.permissions.updateContactEmailAddresses;
                case Umbrella.PersonFieldTypes.PhoneNumber:
                    return window.user.permissions.updateContactPhoneNumbers;
                case Umbrella.PersonFieldTypes.Gender:
                    return window.user.permissions.updateGender;
                case Umbrella.PersonFieldTypes.PostalAddress:
                    return window.user.permissions.updateContactPostalAddress;
                default:
                    return window.user.permissions.customerWrite;
            }
        }

        public enterEditMode() {
            this.clonePerson();
            this.editMode = true;
        }

        public saveAndLeaveEditMode() {
            if (this.updating) return;

            this.updating = true;

            this.person = this.personClone;

            const nrOfAddresses = (this.person && this.person.contactDetails.addresses.length) || 0;
            for (let count = 0; count < nrOfAddresses; count++)
                this.person.contactDetails.addresses[count].houseNumber =
                    this.person.contactDetails.addresses[count].houseNumber || 0;

            this.personInfoService
                .update(this.person)
                .then(() => {
                    this.editMode = false;
                    this.toastMessageService.success('Wijzigingen zijn succesvol opgeslagen.');
                })
                .catch(() => {
                    this.toastMessageService.error('Fout opgetreden bij het opslaan van de wijzigingen.');
                })
                .finally(() => (this.updating = false));
        }

        public cancelAndLeaveEditMode(): void {
            this.clonePerson();
            this.editMode = false;
        }

        public clonePerson(): void {
            this.personClone = JSON.parse(JSON.stringify(this.person));
            if (this.personClone.birthDate) {
                this.personClone.birthDate = this.fixTheDate(
                    new Date(Date.parse(this.personClone.birthDate.toString()))
                );
            }
        }

        private fixTheDate(date: Date) {
            const minutes = date.getMinutes();
            const hours = date.getHours();
            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();

            return new Date(Date.UTC(year, month, day, hours, minutes));
        }
    }
}
